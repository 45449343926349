import React, { useState, useEffect, useContext } from "react";
import "../../../../Css/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBillAlt } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import Sidenav from "../../../../Components/SideNavigation/SideNavigation";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_Endpoint } from "../../../../Configuration/Settings";
import Loader from "../../../../Components/Loader/Loader";
import { decryptData } from "../../../../utils/decryptData";
import { encryptData } from "../../../../utils/encryptData";
import { User_Context } from "../../../../Contexts/User";
import Breadcrumbs from "../../../../Components/Breadcrumbs/Breadcrumbs";
import StatCard from "../../../../Components/Statistics/StatCard";
import DashboardCard from "../../../../Components/Statistics/DashboardCard";
import { dateTime } from "../../../../utils/utility";

interface User {
  _id: string;
  subAdminId: string;
  name: string;
  kyc: false;
  mobile: string;
  email: string;
  password: string;
  state: string;
  city: string;
  balance: number;
  deviceType: string;
  createdOn: string;
  updatedOn: string;
  __v: number;
  totalUserRegisterToday: number;
  totalUserCount: number;
  totalBalance: number;
  liabilities: number;
  totalCountCasino: number;
}

interface CasinoRes {
  totalCountCasino: number;
  totalBetAmountCasino: number;
  casino_profit: number;
  casinoGGR: number;
  qtechGGR: number
  qtechTotalBetAmount: number
  qtechTotalBetCount: number
  qtechTotalBetWinAmount: number
}

interface Sm {
  totalCountSm: number;
  smProfit: number;
  smTotalBetAmount: number;
  smGGr: number;
  sattaMatkaGGR: number
  sattaMatkaTotalBetAmount: number
  sattaMatkaTotalBetCount: number
  sattaMatkaTotalBetPendingAmount: number
  sattaMatkaTotalBetWinAmount: number
}
interface Falcon {
  falconGGR: number
  falconTotalBetAmount: number
  falconTotalBetCount: number
  falconTotalBetPendingAmount: number
  falconTotalBetWinAmount: number
}

interface Exchange {
  totalJtCount: number;
  totalJtBetAmount: number;
  jtProfit: number;
  jtGGR: number;
  jetfairTotalBetAmount: number;
  jetfairTotalBetCount: number;
  jetfairTotalBetPendingAmount: number;
  bettingAmount: number,
  winningAmount: number,
  ggr: number,
  commission: number
}
function Home() {
  const [registeredUser, setRegisteredUser] = useState<number>(0);
  const [regUserWeb, setRegUserWeb] = useState<number>(0);
  const [regUserApp, setRegUserApp] = useState<number>(0);
  const [regUserWebToday, setRegUserWebToday] = useState<number>(0);
  const [regUserAppToday, setRegUserAppToday] = useState<number>(0);
  const [nonPerformingUser, setNonPerformingUser] = useState<number>(0);
  const [bonusBalanceUser, setBonusBalanceUser] = useState<number>(0);
  const [activeUser, setActiveUser] = useState<number>(0);
  const [usersCreatedToday, setUsersCreatedToday] = useState<number>(0);
  const [balance, setBalance] = useState<number>(0);
  const [liability, setLiability] = useState<number>(0);
  const [casinoBets, setCasinoBets] = useState<CasinoRes>();
  const [exchange, setExchange] = useState<Exchange>();
  const [smBets, setSmBets] = useState<Sm>();
  const [falconBets, setFalconBets] = useState<Falcon>();
  const [loading, setLoading] = useState<boolean>(false);
  const { User } = useContext(User_Context)
  const [startDate, setStartDate] = useState<string>(dateTime(new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000).toISOString().split('T')[0]));
  const [endDate, setEndDate] = useState<string>(dateTime(new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000).toISOString().split('T')[0]));
  const [data, setData] = useState<any>({ startDate, endDate })

  const dashboardData = () => {
    const token = localStorage.getItem("token");
    return axios.post(`${API_Endpoint}/User/dashboard`, { token: encryptData(data) }, {
      headers: {
        Authorization: `Bearer ${token}`,
        maxBodyLength: Infinity,
      },
    });
  };

  const newJetFairData = () => {
    const token = localStorage.getItem("token");
    return axios.post(`${API_Endpoint}/jetfair/jetfair-main-ggr`, { token: encryptData(data) }, {
      headers: {
        Authorization: `Bearer ${token}`,
        maxBodyLength: Infinity,
      },
    });
  };

  const handleAllData = (e: any) => {
    let text = e?.target?.id
    if (text == "alldata") {
      setData({})
      setStartDate("")
      setEndDate("")
    } else {
      setData({
        startDate: dateTime(startDate),
        endDate: dateTime(endDate),
      })
    }
  };

  const handleApiCalls = () => {
    setLoading(true)
    axios.all([dashboardData(),newJetFairData()])
    .then(axios.spread((dashboardResponse, newJetfairResponse) => {
      const dashboardData = decryptData(dashboardResponse.data.data);
      const payload = dashboardData.payload;
      setRegisteredUser(payload.totalRegisterUsers);
      setRegUserWeb(payload.totalRegisterUsersOfWeb);
      setRegUserApp(payload.totalRegisterUsersOfApp);
      setRegUserAppToday(payload.totalTodayRegisterUsersOfApp);
      setRegUserWebToday(payload.totalTodayRegisterUsersOfWeb);
      setNonPerformingUser(payload.totalNonPerformingUsers);
      setBonusBalanceUser(payload.totalBonusBalanceOfUsers);
      setActiveUser(payload.totalActiveUsers);
      setUsersCreatedToday(payload.totalTodayRegisterUsers);
      setBalance(payload.totalBalanceOfUsers);
      setLiability(payload.falconTotalBetPendingAmount + payload.jetfairTotalBetPendingAmount + payload.sattaMatkaTotalBetPendingAmount);
      setCasinoBets(payload);
      setSmBets(payload);
      setFalconBets(payload);
      const newJetFairData = decryptData(newJetfairResponse.data.data);
      const newJefairData = newJetFairData.payload
      setExchange(newJefairData);
      setLoading(false);
    }))
    .catch((error) => {
      console.error("Error fetching data:", error);
      setLoading(false);
    });
  }

  useEffect(() => {
    handleApiCalls()
  }, [data]);


  const casinoBetsData = [
    { label: "Total Bet Amount", value: casinoBets?.qtechTotalBetAmount },
    { label: "Total Bet Count", value: casinoBets?.qtechTotalBetCount },
    { label: "Total Win Amount", value: casinoBets?.qtechTotalBetWinAmount },
    { label: "GGR", value: casinoBets?.qtechGGR },
  ];
  const exchangeBetsData = [
    { label: "Total Bet Amount", value: exchange?.bettingAmount },
    { label: "Total Win Amount", value: exchange?.winningAmount },
    { label: "GGR", value: exchange?.ggr },
    { label: "Commission", value: exchange?.commission },
  ];
  const smBetsData = [
    { label: "Total Bet Amount", value: smBets?.sattaMatkaTotalBetAmount },
    { label: "Total Bet Count", value: smBets?.sattaMatkaTotalBetCount },
    { label: "Total Bet Pending Amount", value: smBets?.sattaMatkaTotalBetPendingAmount },
    { label: "Total Win Amount", value: smBets?.sattaMatkaTotalBetWinAmount },
    { label: "GGR", value: smBets?.sattaMatkaGGR },
  ];
  const falconBetsData = [
    { label: "Total Bet Amount", value: falconBets?.falconTotalBetAmount },
    { label: "Total Bet Count", value: falconBets?.falconTotalBetCount },
    { label: "Total Bet Pending Amount", value: falconBets?.falconTotalBetPendingAmount },
    { label: "Total Win Amount", value: falconBets?.falconTotalBetWinAmount },
    { label: "GGR", value: falconBets?.falconGGR },
  ];
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="g-sidenav-show  bg-gray-100">
          <ToastContainer autoClose={2000} position="top-center" />
          <Sidenav />
          <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
            <Breadcrumbs tab={"Dashboard"} />
            <div
              className="container-fluid py-4"
              style={{ background: "#f8f9fa" }}
            >
              <div className="row">
                <div className="row tp-form">
                  <div className="col-6 col-xl-2 col-sm-4 pdrt">
                    <label className="lbl">From Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="From Date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                  <div className="col-6 col-xl-2 col-sm-4 pdrt">
                    <label className="lbl">To Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="To Date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                  <div className="col-6 col-xl-2 col-sm-4 pdrt">
                    <label className="lbl"></label>
                    <a id="apply" className="sechBtn mt-1" onClick={handleAllData}>
                      Apply
                    </a>
                  </div>
                  <div className="col-6 col-xl-2 col-sm-4 pdrt">
                    <label className="lbl"></label>
                    <a id="alldata" className="sechBtn mt-1" onClick={handleAllData}>
                      All Data
                    </a>
                  </div>
                </div>
              </div>
              <div className="row">
                <StatCard title={"Total Users"} icon={faUserCircle} value={Math.round(registeredUser)} className={"fa fa-shopping-cart mid-icon"} />
                <StatCard title={"Total Registered Users Web"} icon={faUserCircle} value={regUserWeb} className={"fa fa-globe mid-icon"} />
                <StatCard title={"Total Registered Users App"} icon={faUserCircle} value={regUserApp} className={"fa fa-globe mid-icon"} />
                <StatCard title={"Total Registered Users Today"} icon={faUserCircle} value={usersCreatedToday} className={"fa fa-globe mid-icon"} />
                <StatCard title={"Total Registered Users Web Today"} icon={faUserCircle} value={regUserWebToday} className={"fa fa-globe mid-icon"} />
                <StatCard title={"Total Registered Users App Today"} icon={faUserCircle} value={regUserAppToday} className={"fa fa-globe mid-icon"} />
                <StatCard title={"Total Users Balance"} icon={faMoneyBillAlt} value={`₹ ${Math.round(balance)}`} className={"fa fa-user-circle-o mid-icon"} />
                <StatCard title={"Total Users Bonus Balance"} icon={faUserCircle} value={`₹ ${Math.round(bonusBalanceUser)}`} className={"fa fa-shopping-cart mid-icon"} />
                <StatCard title={"Total Active Users"} icon={faUserCircle} value={Math.round(activeUser)} className={"fa fa-shopping-cart mid-icon"} />
                <StatCard title={"Total Non Performing Users"} icon={faUserCircle} value={Math.round(nonPerformingUser)} className={"fa fa-shopping-cart mid-icon"} />
                <StatCard title={"Liability"} icon={faShoppingCart} value={`₹ ${Math.round(liability)}`} className={"fa fa-shopping-cart mid-icon"} />
              </div>
              <div className="row mt-4">
                <DashboardCard title="Falcon Bets Today:" data={falconBetsData} />
                <DashboardCard title="Satta Matka Bets Today:" data={smBetsData} />
                <DashboardCard title="Qtech Bets Today:" data={casinoBetsData} />
                <DashboardCard title="Jetfair Bets Today:" data={exchangeBetsData} />
              </div>
              <footer className="footer mt-2  ">
                <div className="container-fluid">
                  <div className="row align-items-center justify-content-lg-between">
                    <div className="col-lg-6 mb-lg-0 mb-4">
                      <div className="copyright text-center text-sm text-muted text-lg-start">
                        © fairbets.co
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <ul className="nav nav-footer justify-content-center justify-content-lg-end">
                        <li className="nav-item">
                          <a
                            href="#"
                            className="nav-link text-muted"
                            target="_blank"
                          />
                        </li>
                        <li className="nav-item">
                          <a
                            href="#/presentation"
                            className="nav-link text-muted"
                            target="_blank"
                          >
                            About Us
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            href="#/blog"
                            className="nav-link text-muted"
                            target="_blank"
                          >
                            Blog
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            href="#/license"
                            className="nav-link pe-0 text-muted"
                            target="_blank"
                          >
                            License
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </main>
        </div>
      )}
    </>
  );
}

export default Home;