import { useContext, useState, useEffect, ChangeEvent } from "react";
import { ToastContainer } from "react-toastify";
import Sidenav from "../../../../Components/SideNavigation/SideNavigation";
import { Pagination, Stack } from "@mui/material";
import ExcelExport from "../../../../Excel/ExcelExport";
import axios from "axios";
import { API_Endpoint } from "../../../../Configuration/Settings";
import Loader from "../../../../Components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { decryptData } from "../../../../utils/decryptData";
import { encryptData } from "../../../../utils/encryptData";
import { User_Context } from "../../../../Contexts/User";
import "../../../../Css/style.css";
import "../../../../Css/table.css";
import "../../../../Css/users.css";
import Breadcrumbs from "../../../../Components/Breadcrumbs/Breadcrumbs";
import SearchBar from "../../../../Components/SearchBox/Search";
import Stateful_Select from "../../../../Components/Dropdown/Dropdown";
import "./MasterFlow.css";
import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import Reusable_Input from "../../../../Components/InputField/InputField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilSquare, faTrashCan, faPlus } from "@fortawesome/free-solid-svg-icons";
import { TextField } from "@material-ui/core";
import CloseIcon from '@mui/icons-material/Close';
import Switch from "@mui/material/Switch";
import { faList } from "@fortawesome/free-solid-svg-icons";
interface PLData {
    _id: string;
    name: string;
    mobile: string;
    initialWalletBalance: number;
    currentWalletBalance: number;
    referenceBalance: number;
    isActive: boolean;
    ownShare: number;
    exposer: number;
    pnl: number;
    createdBy: {
        _id: string,
        role: string,
        name: string
    };
    transferAmount: number;
    type: string;
    uplineShare:number;
    totalPnl:number;
    // betAmount: number;
    // totalProfit: number;
    // totalLoss: number;
    // withdrawl: number;
    // bonus: number;
    // deposite: number;
    // mobile: number;
}
export default function AdminTable({page, onUpdate }: {page:number, onUpdate: () => void }) {

    const [searchUserId, setSearchUserId] = useState<string>("");
    const [searchUserName, setSearchUserName] = useState<string>("");
    const [searchUserMob, setSearchUserMob] = useState<string>("");
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(page || 1);
    const [loading, setLoading] = useState<boolean>(false);
    const Navigate = useNavigate();
    const { User } = useContext(User_Context);
    const [itemsPerPage, setItemsPerPage] = useState<number>(10);
    const [editPlayerPopup, setEditPlayerPopup] = useState<boolean>(false);
    const [editTransferAmt, setEditTransferAmt] = useState<number>(0);
    const [refEditTransferAmt, setRefEditTransferAmt] = useState<number>(0);
    const [transferOperation, setTransferOperation] = useState<string>("");
    const [editTransferId, setEditTransferId] = useState<string>("");
    const [editTransferRole, setEditTransferRole] = useState<string>("");
    const [editTransferName, setEditTransferName] = useState<string>("");
    const [editTransferCreatedBy, setEditTransferCreatedBy] = useState<object>({});
    const [age, setAge] = React.useState('');
    const [playerName, setPlayerName] = useState<string>("");
    const [playerRole, setPlayerRole] = useState<string>("");
    const [playerBalance, setPlayerBalance] = useState<number>();
    const [playerLimit, setPlayerLimit] = useState<number>();
    const [addPlayerPopup, setAddPlayerPopup] = useState<boolean>(false);
    const [addRemoveBalance, setAddRemoveBalance] = useState<number>();
    const [addRemoveErr, setAddRemoveErr] = useState<boolean>(false);
    const [addRemoveText, setAddRemoveText] = useState<string>("");
    const [deletePopup, setDeletePopup] = useState<boolean>(false);
    const [id, setId] = useState<string>("");
    const label = { inputProps: { "aria-label": "Switch demo" } };
    const [selectedOption, setSelectedOption] = useState<string>('');

    const [adminData, setAdminData] = React.useState<Array<PLData>>([])
    const [addPopup, setAddPopup] = useState<boolean>(false);


    const [name, setName] = useState<string>("");
    const [nameError, setNameError] = useState<boolean>(false);
    const [nameHelperText, setNameHelperText] = useState<string>("");

    const [balance, setBalance] = useState<string>("");
    const [balanceError, setBalanceError] = useState<boolean>(false);
    const [balanceHelperText, setBalanceHelperText] = useState<string>("");

    const [ownShare, setOwnShare] = useState<string>("");
    const [ownShareError, setOwnShareError] = useState<boolean>(false);
    const [ownShareHelperText, setOwnShareHelperText] = useState<string>("");

    const [mobile, setMobile] = useState<string>("");
    const [mobileError, setMobileError] = useState<boolean>(false);
    const [mobileHelperText, setMobileHelperText] = useState<string>("");

    // const [email, setEmail] = useState<string>("");
    // const [emailError, setEmailError] = useState<boolean>(false);
    // const [emailHelperText, setEmailHelperText] = useState<string>("");

    const [password, setPassword] = useState<string>("");
    const [passwordError, setPasswordError] = useState<boolean>(false);
    const [passwordHelperText, setPasswordHelperText] = useState<string>("");
    const [remainingShare, setRemainingShare] = useState<number>(User.data.ownShare || 0);

    const [uplineShareEdit, setUplineShareEdit] = useState<number>(0);
    const [ownShareEdit, setOwnShareEdit] = useState<number>(0);

    const handleChange = (event: SelectChangeEvent) => {
        setAge(event.target.value as string);
    };

    const handleSelectedOption = (event: SelectChangeEvent, id: string) => {
        const stateData = {
            menuItem: event.target.value as string,
            id: id
        }
        setSelectedOption(event.target.value as string);
        Navigate("/detail-master-flow", { state: stateData })
    };

    const handleSearchUserId = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchUserId(event.target.value);
    };
    const handleSearchUserName = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchUserName(event.target.value);
    };
    const handleSearchUserMob = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchUserMob(event.target.value);
    };

    const filterUser = () => {
        setLoading(true);
        const filter: { _id?: string, name?: string, mobile?: string } = {};
        if (searchUserId) {
            filter._id = searchUserId
        }
        if (searchUserName) {
            filter.name = searchUserName
        }
        if (searchUserMob) {
            filter.mobile = searchUserMob
        }
        const token = localStorage.getItem("token");
        let data = {
            pageSize: 10,
            pageNumber: currentPage,
            filter: filter
        };
        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${API_Endpoint}/User/getProfitLoss`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: { token: encryptData(data) },
        };

        axios
            .request(config)
            .then((response) => {
                response.data.data = decryptData(response.data.data)
                const pages = response.data.data.payload.count / 10;
                setTotalPages(Math.ceil(pages));
                //setPlData(response.data.data.payload.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false)
            });
    }

    
    const fetchData = async () => {

        try {
            // const token = localStorage.getItem("token");
            // localhost:7000/api/masterFlow/getPlayersList

            let config = {
                method: "post",
                url: `${API_Endpoint}/masterFlow/getAdminList`,
                headers:
                {
                    Authorization: `Bearer ${User.token}`,
                },
                data: {
                    token: encryptData({
                        _id: User.data?._id,
                        role: User.data?.type,
                        itemsPerPage: itemsPerPage,
                        pageNo: currentPage
                        // "_id": "65c3680609d7e1935d62c98c",
                        // "role": "superAdmin"
                    })
                }
            };
            let response = await axios.request(config)
            let deResponse = decryptData(response.data.data)
            setAdminData(deResponse.payload.items);
            setTotalPages(deResponse.payload.totalPages);
        } catch (error: any) {
            console.log(error.message)
        }
    };


    useEffect(() => {

        fetchData();
    }, [currentPage, itemsPerPage]);

    const handlePerPage = (newValue: any) => {
        setItemsPerPage(newValue)
    }

    const openAddDialog = () => {
        setAddPopup(true)
    }

    const handleClosePopup = () => {
        setLoading(false);
        setAddPopup(false)
        setName("")
        setMobile("")
        // setEmail("")
        setBalance("")
        setPassword("")
        setOwnShare("")
    }
    const handleSubmitData = (event: React.FormEvent) => {
        event.preventDefault()
        if (!name) {
            setNameError(true);
            setNameHelperText("Enter name")
        } else if (!mobile) {
            setMobileError(true);
            setMobileHelperText("Enter mobile");
        }
        // else if (!email) {
        //     setEmailError(true);
        //     setEmailHelperText("Enter email");
        // }
        else if (!balance) {
            setBalanceError(true);
            setBalanceHelperText("Enter opening balance");
        }
        else if (!password) {
            setPasswordError(true);
            setPasswordHelperText("Enter password")
        }
        else if (!ownShare) {
            setOwnShareError(true)
            setOwnShareHelperText("Enter partnership %")
        }
        else {
            const token = localStorage.getItem("token");
            setLoading(true)
            let data = {
                type: "admin",
                name: name,
                // email,
                mobile,
                password,
                ownShare: parseInt(ownShare),
                uplineShare:remainingShare,
                initialWalletBalance: parseInt(balance),
                currentWalletBalance: parseInt(balance),
                Role_ID: "65cc61b68fe9b74c9b0d7e29",
                createdBy: {
                    "_id": User.data._id,
                    "name": User.data.name,
                    "role": User.data.type
                }
            }
            axios
                .post(
                    `${API_Endpoint}/masterFlow/createAdmin`,
                    {
                        token: encryptData(data)
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then((response) => {
                    if (response.data.success) {
                        response.data.data = decryptData(response.data.data)
                        setAdminData(prevData => [...prevData, response.data.data?.payload]);
                        toast.info("Admin created successfully!")
                        setLoading(false);
                        setAddPopup(false)
                        setName("")
                        setMobile("")
                        // setEmail("")
                        setBalance("")
                        setPassword("")
                        setOwnShare("")
                        onUpdate()
                    }
                    else {
                        toast.error(response.data.message)
                    }
                })
                .catch((err: any) => {
                    toast.error("something went wrong!")
                    setLoading(false);
                });
        }
    }



    const handleInputDialog = (event: ChangeEvent<HTMLInputElement>, inputType: String) => {

        if (inputType == "name") {
            setName(event.target.value);
            setNameError(false);
        }

        else if (inputType == "balance") {
            setBalance(event.target.value);
            setBalanceError(false);
        }
        // else if (inputType == "email") {
        //     setEmail(event.target.value);
        //     setEmailError(false);
        // }
        else if (inputType == "password") {
            setPassword(event.target.value);
            setPasswordError(false);
        }
        else if (inputType == "mobile") {
            setMobile(event.target.value);
            setMobileError(false);
        }
        else if (inputType == "ownShare") {
            const share = User.data?.ownShare ?? 0
            const currShare = parseInt(event.target.value|| '0')
            if (currShare <= share) {
                setRemainingShare(share - Math.abs(currShare))
                setOwnShare(event.target.value);
                setOwnShareError(false);
            }
        }

    };

    const openEditDialog = (amount: number, id: string, type: string, createdBy: object, name: string,uplineShare:number, ownShare:number) => {
        // setRefEditTransferAmt(Math.abs(amount))
        // setTransferOperation(amount<0?"add":"remove")
        // setEditTransferAmt(Math.abs(amount))
        // setEditTransferId(id)
        // setEditTransferRole(type)
        // setEditTransferCreatedBy(createdBy)
        // setEditTransferName(name)
        // setEditPlayerPopup(true)
        setRefEditTransferAmt(Math.abs(Number(amount.toFixed(2))))
        setTransferOperation(amount<0?"add":"remove")
        setEditTransferAmt(Math.abs(Number(amount.toFixed(2))))
        // setActualTransferAmt((amount/uplineShare)*ownShare)
        setUplineShareEdit(uplineShare)
        setOwnShareEdit(ownShare)
        setEditTransferId(id)
        setEditTransferRole(type)
        setEditTransferCreatedBy(createdBy)
        setEditTransferName(name)
        setEditPlayerPopup(true)
    };

    const updateTransferAmount = () => {
        //console.log("editTransferRole",editTransferRole);

        setLoading(true);

        let actualTransferAmt = 0
        if(editTransferAmt){
          actualTransferAmt = ((editTransferAmt/(uplineShareEdit === 0 ? 100 : uplineShareEdit))*ownShareEdit)
        }

        const token = localStorage.getItem("token");
        axios
            .post(`${API_Endpoint}/masterFlow/transferOne`, { token: encryptData({ _id: editTransferId, role: editTransferRole, amount: transferOperation=="add"?actualTransferAmt:-actualTransferAmt}) }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                if (response.data.success) {
                    // let deResponse = decryptData(response.data.data)
                    // let updatedItem = deResponse.payload
                    // const updatedItems = adminData.map(item => {
                    //     if (item._id === updatedItem._id) {
                    //         return { ...item, transferAmount: 0 };
                    //     }
                    //     return item;
                    // });
                    // setAdminData(updatedItems);
                    fetchData()
                    onUpdate()
                    toast.success("Transfer settled successfully");
                    setEditPlayerPopup(false)
                    setLoading(false);
                }
                else {
                    toast.error(response.data.message);
                    setEditPlayerPopup(false)
                    setLoading(false);
                }
            })
            .catch((error: any) => {
                setLoading(false);
                toast.error("Transfer settelment failed!");
            });
    }

    const openAddPlayerPopup = (id: string, type: string, name: string, balance: number, limit: number) => {
        setId(id)
        setPlayerRole(type)
        setPlayerName(name)
        setPlayerBalance(balance)
        setAddPlayerPopup(true)
        setPlayerLimit(limit)
    }

    const handleAddRemove = (e: ChangeEvent<HTMLInputElement>) => {
        setAddRemoveBalance(parseInt(e.target.value));
    };

    function checkBalance(creditLimit: number = 0, availableBalance: number = 0, balanceToUpdate: number = 0, operation: string) {
        if (operation === "Add") {
            // Check if availableBalance + balanceToUpdate exceeds creditLimit
            //   return (availableBalance + balanceToUpdate) <= creditLimit;
            return true
        } else if (operation === "remove") {
            // Check if balanceToUpdate is greater than availableBalance
            return balanceToUpdate <= availableBalance;
        } else {
            // Handle invalid operation
            throw new Error("Invalid operation. Operation must be 'add' or 'remove'.");
        }
    }

    const handleUpdateDw = (transactionType: string) => {

        if (checkBalance(playerLimit, playerBalance, addRemoveBalance, transactionType)) {
            setLoading(true);

            const token = localStorage.getItem("token");
            axios
                .post(`${API_Endpoint}/masterFlow/updateBalance`, { token: encryptData({ _id: id, role: playerRole, transactionType, amount: addRemoveBalance, updatedBy: User.data?._id, name: playerName }) }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((response) => {
                    if (response.data.success) {
                        let deResponse = decryptData(response.data.data)
                        let updatedItem = deResponse.payload
                        const updatedItems = adminData.map(item => {
                            if (item._id === updatedItem._id) {
                                return { ...item, initialWalletBalance: updatedItem.initialWalletBalance, currentWalletBalance: updatedItem.currentWalletBalance };
                            }
                            return item;
                        });
                        setAdminData(updatedItems);
                        toast.success("Balance updated successfully");
                        setAddPlayerPopup(false);
                        setLoading(false);
                        onUpdate();
                        setAddRemoveBalance(0);
                    }
                    else {
                        toast.error(response.data.message);
                        setAddPlayerPopup(false);
                        setLoading(false);
                        setAddRemoveBalance(0);
                    }
                })
                .catch((error: any) => {
                    setLoading(false);
                    setAddPlayerPopup(false);
                    toast.error("Update balance failed!");
                });
        }
        else {
            toast.error("Amount should be less than available balance");
            // if(transactionType == "Add"){
            //     toast.error("Add Amount exceeds credit limit");
            // }
            // else{
            //     toast.error("Amount should be less than available balance");
            // }
        }

    }

    const deletePlayer = () => {
        // setLoading(true);
        // setEditPlayerPopup(false);
        // const token = localStorage.getItem("token");
        // axios
        //   .post(`${API_Endpoint}/upiLists/delete`, { token: encryptData({ _id: id }) }, {
        //     headers: {
        //       Authorization: `Bearer ${token}`,
        //     },
        //   })
        //   .then(() => {
        //     const updatedUpiData = playerDate.filter((item) => item._id !== id);
        //     setPlayerDate(updatedUpiData);
        //     setLoading(false);
        //   })
        //   .catch((error) => {
        //     setLoading(false);
        //     console.log("error", error);
        //   });
    }

    const openDeletePopup = (id: string) => {
        setId(id)
        setDeletePopup(true);
    };

    const handleStatusChange = (event: ChangeEvent<HTMLInputElement>, id: string, role: string, status: boolean) => {
        setLoading(true);
        //  setEditPlayerPopup(false);
        const token = localStorage.getItem("token");
        axios
            .post(`${API_Endpoint}/masterFlow/updateUserStatus`, { token: encryptData({ _id: id, role, status: !status }) }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                if (response.data.success) {
                    let deResponse = decryptData(response.data.data)
                    let updatedItem = deResponse.payload
                    const updatedItems = adminData.map(item => {
                        if (item._id === updatedItem._id) {
                            return { ...item, isActive: updatedItem.isActive };
                        }
                        return item;
                    });

                    setAdminData(updatedItems);
                    toast.success("status updated successfully!");
                    setEditPlayerPopup(false)
                    setLoading(false);
                }
                else {
                    toast.error(response.data.message);
                    setEditPlayerPopup(false)
                    setLoading(false);
                }
            })
            .catch((error: any) => {
                setLoading(false);
                toast.success("status update failed!");
                // console.log("error", error);
            });
    }

    const editTransferAmount = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = parseFloat(e.target.value);
        if ((newValue?newValue:0) <= (refEditTransferAmt) && (newValue?newValue:0) >= 0) {
            setEditTransferAmt(newValue?newValue:0);
        }
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="row tp-form">

                        <div className="col-6 col-xl-2 col-sm-4 pdrt btn-top role-mob role-web mt-2">
                            <Stateful_Select
                                label="Items Per Page"
                                value={itemsPerPage.toString()}
                                onChange={(newValue: any) => handlePerPage(newValue)}
                                options={["10", "25", "50", "75", "100"]}
                            />
                        </div>
                        <div className="col-6 col-xl-2 col-sm-4 pdrt btn-top role-mob role-web mt-2">
                            <Button onClick={openAddDialog} className="addUser" variant="contained">Add Admin</Button>
                        </div>
                    </div>
                    <div className="col-12 mt-2">
                        <div className="table-responsive">
                            <table className="table table-view">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Master Name</th>
                                        <th>Mobile</th>
                                        <th>Credit Limit</th>
                                        <th>Available Balance</th>
                                        <th>Exposure</th>
                                        {/* <th>P/L</th> */}
                                        {/* <th>Own Share</th> */}
                                        <th>Take</th>
                                        <th>Give</th>
                                        <th>Partnership</th>
                                        <th>Transfer Amount</th>
                                        <th>status</th>
                                        <th>Action</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                {/* <thead>
                                    <tr className="bg-table">
                                        <th className="thdr">
                                        </th>
                                        <th className="thdr">
                                            <div className="d-flex justify-content-center">
                                                <SearchBar value={searchUserId} onChange={handleSearchUserId} onSearch={filterUser} placeholder="Search by user id" />
                                            </div>
                                        </th>
                                        <th className="thdr">
                                            <div className="d-flex justify-content-center">
                                                <SearchBar value={searchUserName} onChange={handleSearchUserName} onSearch={filterUser} placeholder="Search by user name" />
                                            </div>
                                        </th>
                                        <th className="thdr">
                                            <div className="d-flex justify-content-center">
                                                <SearchBar value={searchUserMob} onChange={handleSearchUserMob} onSearch={filterUser} placeholder="Search by user mobile" />
                                            </div>
                                        </th>
                                        <th className="thdr"></th>
                                        <th className="thdr"></th>
                                        <th className="thdr"></th>
                                        <th className="thdr"></th>
                                        <th className="thdr"></th>
                                        <th className="thdr"></th>
                                        <th className="thdr"></th>
                                    </tr>
                                </thead> */}
                                <tbody>
                                    {adminData.map((item, index) => (
                                        <tr id={item._id}>
                                            <td>{index + 1}</td>
                                            <td>{item.name}</td>
                                            <td>{item.mobile}</td>
                                            {/* old credit limit
                                            <td>{item.referenceBalance}</td> */}
                                            {/* new credit limit concept */}
                                            <td>{item.initialWalletBalance}</td>
                                            <td>{item.currentWalletBalance}</td>
                                            <td>{(item.exposer).toFixed(2)  ?? 0}</td>
                                            {/* <td>
                                                <span className={(item.pnl)>0?"green":(item.pnl)<0?"red":""}>
                                                 <b>{(item.pnl)?.toFixed(2) ?? 0}</b>
                                                </span>
                                            </td> */}
                                            {/* <td>{item.createdBy?._id == User.data._id && 
                                            (Math.abs((item.uplineShare/100)*item.totalPnl)).toFixed(2) 
                                            }</td> */}
                                            <td>
                                                <span className={'green'}>
                                                <b>
                                                     {
                                                        item.pnl > 0 ?
                                                        // (Math.abs((item.uplineShare/100)*item.totalPnl)).toFixed(2)
                                                        (Math.abs((item.pnl/item.ownShare)*(item.uplineShare === 0 ? 100 : item.uplineShare))).toFixed(2)
                                                            :
                                                            ""
                                                    }
                                                </b>    
                                                </span>
                                            </td>
                                            <td>
                                                <span className={'red'}>
                                                <b>
                                                     {
                                                        item.pnl < 0 ?
                                                            // (Math.abs(item.pnl)).toFixed(2)
                                                            // (Math.abs((item.uplineShare/100)*item.totalPnl)).toFixed(2)
                                                            (Math.abs((item.pnl/item.ownShare)*(item.uplineShare === 0 ? 100 : item.uplineShare))).toFixed(2)
                                                            :
                                                            ""
                                                    }
                                                </b>    
                                                </span>
                                            </td>

                                            {/* <td>{item.isActive == true?"active":"inactive"}</td> */}
                                            <td>{item.ownShare}</td>
                                            <td>
                                                {/* {item.transferAmount} */}
                                                {/* <span className={item.transferAmount > 0 ? "green" : item.transferAmount < 0 ? "red" : ""}>
                                                    <b>{Math.abs((item.transferAmount)).toFixed(2)}</b>
                                                </span> */}
                                                {(Math.abs((item.pnl/item.ownShare)*(item.uplineShare === 0 ? 100 : item.uplineShare))).toFixed(2)}
                                                {item.createdBy?._id == User.data._id &&
                                                    <span className="span-transferamt">
                                                        <FontAwesomeIcon
                                                            id={item._id}
                                                            className="fa fa-pencil-square icon-home icon-banner"
                                                            icon={faPencilSquare}
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => openEditDialog(((item.pnl/item.ownShare)*(item.uplineShare === 0 ? 100 : item.uplineShare)), item._id, item.type, item.createdBy, item.name,item.uplineShare,item.ownShare)}
                                                        />
                                                    </span>
                                                }
                                            </td>
                                            {
                                                item.createdBy?._id == User.data._id ?
                                                    <td>
                                                        <Switch
                                                            {...label}
                                                            id={item._id}
                                                            checked={item.isActive}
                                                            onChange={(event) => handleStatusChange(event, item._id, item.type, item.isActive)}
                                                        />
                                                    </td> :
                                                    <td>{item.isActive ? "active" : "inactive"}</td>
                                            }

                                            <td>
                                                <span className="d-flex justify-content-center">
                                                    {item.createdBy?._id == User.data._id && item.isActive &&
                                                        <span style={{ marginRight: '10px' }}>
                                                            <FontAwesomeIcon
                                                                id={item._id}
                                                                className="fa fa-pencil-square icon-home icon-trash "
                                                                icon={faPlus}
                                                                onClick={() => openAddPlayerPopup(item._id, item.type, item.name, item.currentWalletBalance, item.referenceBalance,)}
                                                                style={{ cursor: "pointer" }}
                                                            />
                                                        </span>
                                                    }
                                                    <FontAwesomeIcon
                                                        id={item._id}
                                                        className="fa fa-pencil-square icon-home icon-trash "
                                                        icon={faList}
                                                        onClick={() => {
                                                            // const url = `/user-report-master/${item._id}/${item.name}`;
                                                            const url = `/user-report-master/${item._id}/${item.name}/${currentPage}/admin`;
                                                            Navigate(url);
                                                        }}
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                </span>
                                            </td>
                                            <td>
                                                <div className="withdraw-select">
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Select Option</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={selectedOption}
                                                            label="Status"
                                                            onChange={(event) => handleSelectedOption(event, item._id)}
                                                        >
                                                            <MenuItem value={"subAdmin"}>Sub Admin</MenuItem>
                                                            <MenuItem value={"superMaster"}>Super Master</MenuItem>
                                                            <MenuItem value={"master"}>Master</MenuItem>
                                                            <MenuItem value={"player"}>Player</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <ul className="pagination d-flex justify-content-center">
                            <Stack spacing={2}>
                                <Pagination
                                    count={totalPages}
                                    color="secondary"
                                    page={currentPage}
                                    onChange={(event, newPage) => setCurrentPage(newPage)}
                                />
                            </Stack>
                        </ul>
                    </div>
                </div>
                <div>
                    <Dialog open={addPopup} onClose={handleClosePopup}>
                        <DialogContent>
                            <form>
                                <div >
                                    <Reusable_Input type={"text"} label={"Enter  Name"} fullWidth={true} value={name} error={nameError} helperText={nameHelperText} onChange={(e) => handleInputDialog(e, "name")} />
                                </div>
                                <div className="mt-2">
                                    <Reusable_Input type={"text"} label={"Enter Mobile number"} fullWidth={true} value={mobile} error={mobileError} helperText={mobileHelperText} onChange={(e) => handleInputDialog(e, "mobile")} />
                                </div>
                                {/* <div className="mt-2">
                                    <Reusable_Input type={"text"} label={"Enter Email"} fullWidth={true} value={email} error={emailError} helperText={emailHelperText} onChange={(e) => handleInputDialog(e, "email")} />
                                </div> */}
                                <div className="mt-2 mb-2">
                                    <Reusable_Input type={"text"} label={"Enter Opening Balance"} fullWidth={true} value={balance} error={balanceError} helperText={balanceHelperText} onChange={(e) => handleInputDialog(e, "balance")} />
                                </div>
                                <div>
                                    <p>{`remaining share is ${remainingShare}`}</p>
                                </div>
                                <div className="mt-2 mb-2">
                                    <Reusable_Input type={"text"} label={"Enter profit percentage"} fullWidth={true} value={ownShare} error={ownShareError} helperText={ownShareHelperText} onChange={(e) => handleInputDialog(e, "ownShare")} />
                                </div>
                                <div className="mt-2 mb-2">
                                    <Reusable_Input type={"text"} label={"Enter Password"} fullWidth={true} value={password} error={passwordError} helperText={passwordHelperText} onChange={(e) => handleInputDialog(e, "password")} />
                                </div>
                                <DialogActions>
                                    <Button
                                        className="btn-popup"
                                        variant="outlined"
                                        onClick={handleClosePopup}
                                        color="primary"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className="btn-popup"
                                        variant="outlined"
                                        type="submit"
                                        color="primary"
                                        onClick={handleSubmitData}
                                    >
                                        Submit
                                    </Button>
                                </DialogActions>
                            </form>
                        </DialogContent>
                    </Dialog>
                </div>
                <div>
                    <Dialog open={editPlayerPopup} onClose={() => setEditPlayerPopup(false)}>
                        <DialogContent>
                            <div className="mt-2">
                                <label>Transfer Amount</label>
                                <TextField variant="outlined" size="small" className="mt-2" value={Math.abs(editTransferAmt)} onChange={editTransferAmount} type={"number"} fullWidth={true} inputProps={{ step: "0.01",type:"Number"}}/>
                            </div>
                            <DialogActions className="mt-2">
                                <Button
                                    className="btn-popup"
                                    variant="outlined"
                                    onClick={() => setEditPlayerPopup(false)}
                                    color="primary"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="btn-popup"
                                    variant="outlined"
                                    type="submit"
                                    color="primary"
                                    onClick={() => updateTransferAmount()}
                                >
                                    Transfer
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </Dialog>
                </div>
                <div>
                    <Dialog open={addPlayerPopup} onClose={() => setAddPlayerPopup(false)}>
                        <div className="d-flex align-items-center justify-content-between">
                            <DialogTitle>Update Balance</DialogTitle>
                            <span className="span-addplayer" onClick={() => setAddPlayerPopup(false)}>
                                <CloseIcon />
                            </span>
                        </div>
                        <DialogContent>
                            <div className='mt-1 text-inp'>
                                <label>Player Name</label>
                                <TextField variant="outlined" size="small" className="mt-2" value={playerName} disabled type={"text"} fullWidth={true} />
                            </div>
                            <div className='mt-2 mb-4 text-inp'>
                                <label>Available Balance</label>
                                <TextField disabled variant="outlined" size="small" className="mt-2" value={playerBalance?.toFixed(2)} type={"number"} fullWidth={true} />
                            </div>
                            <div className='mt-2 mb-4 text-inp'>
                                <label>Add/Remove Balance</label>
                                <TextField variant="outlined" label={"Enter balance"} size="small" className="mt-2" error={addRemoveErr} helperText={addRemoveText} value={addRemoveBalance} onChange={handleAddRemove} type={"number"} fullWidth={true} />
                            </div>
                            <DialogActions className="mt-n3">
                                <Button
                                    className="btn-popup player-d"
                                    variant="outlined"
                                    type="submit"
                                    color="primary"
                                    onClick={() => handleUpdateDw("Add")}
                                >
                                    Add
                                </Button>
                                <Button
                                    className="btn-popup player-w"
                                    variant="outlined"
                                    onClick={() => handleUpdateDw("remove")}
                                    color="primary"
                                >
                                    Remove
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </Dialog>
                </div>
                <div>
                    <Dialog open={deletePopup} onClose={() => setDeletePopup(false)}>
                        <DialogTitle>Are You Sure ?</DialogTitle>
                        <DialogContent>
                            <DialogActions className="mt-n3">
                                <Button
                                    className="btn-popup"
                                    variant="outlined"
                                    onClick={() => setDeletePopup(false)}
                                    color="primary"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="btn-popup"
                                    variant="outlined"
                                    type="submit"
                                    color="primary"
                                // onClick={() => deletePlayer()}
                                >
                                    Delete
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </Dialog>
                </div>
                <footer className="footer">
                    <div className="container-fluid">
                        <div className="row align-items-center justify-content-lg-between">
                            <div className="col-lg-6 mb-lg-0  ">
                                <div className="copyright text-center text-sm text-muted text-lg-start">
                                    © fairbets.vip
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <ul className="nav nav-footer justify-content-center justify-content-lg-end">
                                    <li className="nav-item">
                                        <a className="nav-link text-muted" target="_blank">
                                            Home
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link text-muted" target="_blank">
                                            About Us
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link text-muted" target="_blank">
                                            Blog
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className="nav-link pe-0 text-muted"
                                            target="_blank"
                                        >
                                            License
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    );
}